export const links = [
  {
    id: 1,
    text: "loomians",
    url: "/loomians",
  },
  {
    id: 2,
    text: "moves",
    url: "/moves",
  },
  {
    id: 3,
    text: "abilities",
    url: "/abilities",
  },
  {
    id: 4,
    text: "items",
    url: "/items",
  },
  {
    id: 5,
    text: "types",
    url: "/types",
  },
  // {
  //   id: 6,
  //   text: "contact us",
  //   url: "/contact",
  // },
];
